<template>
  <div class="p404 main_width">
    <img class="pic" src="@/assets/images/common/404.png" alt="" />
    <p class="txt">哎呀，你访问的页面出错啦！</p>
    <div class="reason_title">可能原因：</div>
    <ul class="reasonList">
      <li>网络信号弱</li>
      <li>找不到请求页面</li>
      <li>输入的网址不正确</li>
    </ul>
    <div class="goback" @click="goback">返回首页</div>
  </div>
</template>

<script>

export default {
  data () {
    return {}
  },
  methods: {
    goback () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.p404 {
  height: 693px;
  margin-top: 158px;
  margin-bottom: 100px;
  background: #ffffff;
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 107px;
  .pic {
    width: 418px;
    height: 161px;
  }
  .txt {
    font-size: 22px;
    font-family: Microsoft YaHei;
    color: #333333;
    margin-top: 28px;
  }
  .reason_title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    color: #999999;
    margin-top: 38px;
    margin-bottom: 20px;
  }
  .reasonList {
    li {
      font-size: 20px;
      font-family: Microsoft YaHei;
      line-height: 31px;
      color: #999999;
      list-style-type: disc;
      margin-left: 60px;
    }
  }
  .goback {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 53px;
    border: 1px solid #999999;
    border-radius: 100px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: #999999;
    margin-top: 52px;
    cursor: pointer;
  }
}
</style>
